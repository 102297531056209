import React, { createContext, useState } from "react"

export const SidebarContext = createContext({
  showDrawer: false,
  toggleDrawer: () => {},
});

export default ({children}) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };
  return (
    <SidebarContext.Provider value={{ showDrawer, toggleDrawer }}>
      {children}
    </SidebarContext.Provider>
  );
};



